import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import styled from "styled-components"
import gsap from 'gsap';
import { useForm } from "react-hook-form";
import FormContext from "../components/ValuationForm/FormContext";
import Container from '../components/Container'
import SectionHeader from '../components/Typography/SectionHeader';
import { PureButton } from '../components/Button/PureButton'
import Loader from '../components/Utils/Loader';
import { ErrorMessage } from "@hookform/error-message"
import Error from "../../static/error.svg"
import { graphql } from "gatsby"
import Seo from "../components/seoComponent"
import Layout from "../layout/layout"
import { websiteUrl } from "../data/navItems"
import useSiteMetadata from "../hooks/useSiteMetadata"
import scrollTo from "gatsby-plugin-smoothscroll"

const SectionContact = styled.section`
    display: grid;
        place-items: center;
    padding: var(--firstSectionPadding);
    min-height: calc(100vh - 127px);
    opacity: 0;
    
    .contact_me {
        color: var(--black);
        
        a {
            position: relative;
        }
        
        p {
            color: var(--textLighter);
            text-align: start;
            margin-bottom: 50px;
        }
    }
    
    .thanks {
        display: grid;
        place-items: center;
        opacity: 1;
    }
    
    .contact-container {
        // border: 1px solid var(--formWrapperBorderColor);
        padding: 5rem 4rem;
        background: linear-gradient(var(--white), #fff);
        border-radius: 15px;
        opacity: 1;
        box-shadow: 
            0 2.8px 2.2px rgba(0, 0, 0, 0.02),
            0 6.7px 5.3px rgba(0, 0, 0, 0.028),
            0 12.5px 10px rgba(0, 0, 0, 0.035);
    }
    
    @media (max-width: 768px) {
        .contact-container {
            padding: 0;
            background: none;
            box-shadow: none;
        }
    }
`;

const ContentWrapper = styled.div`
    grid-column-gap: 60px;
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const StyledForm = styled.form`
    text-align: right;
    margin-bottom: 0;
    
    ul {
        list-style: none;
        margin: 0;
    
        li {
            display: flex;
            flex-direction: column;
        }
    }
    
    .subject {
        display: none;
    }
`;

const UserSumbmission = styled.div`
    display: block;
`;

const NameAndMail = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;
`;

const Message = styled.div`
    margin-top: 30px;
    
    @media (max-width: 762px) {
        margin-top: 2rem;
    }  
`;

const InputWrapper = styled.div`
    border-radius: 15px;
    position: relative;
    
    input, textarea {
        width: 100%;
        background: var(--formInputBg);
        border: 1px solid #f0f0f0;
        color: var(--textNormal);
        padding: 1em;
        border-radius: 5px;
        -webkit-appearance: none;
    
        &:focus {
            outline: none;
            //box-shadow: 0 0 5px 2px #1589BA;
            border: 1px solid var(--primary);
        }
    }
    
    label {
        position: absolute;
            top: 18px;
            left: 14px;
        transform-origin: left;
        transition: transform .3s ease-in-out;
        color: var(--formInputColor);
        cursor: text;
    }
    
    input:focus ~ label,
    input:not(:placeholder-shown) ~ label,
    textarea:focus + label,
    textarea:not(:placeholder-shown) ~ label {
        border: none;
        color: #868686;
        transform: translate(-9px, -40px) scale(.6);
    }
    
    small {
        //position: absolute;
        //    left: 0;
        //    bottom: -23px;
        display: flex;
            align-items: center;
        color: red;
            text-align: left;
            font-size: .75rem;
        width: 100%;
        
        svg {
            max-height: 15px;
            max-width: 15px;
            margin-right: 4px;
        }
    }
`;

const ContactPage = ({ location }) => {

    const url = `${websiteUrl + location.pathname}`;
    const { windowGlobal } = useContext(FormContext);
    const { register, handleSubmit, formState: { errors }, reset} = useForm();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');
    const [formSent, setFormSent] = useState('unsent');

    const { i18n, t } = useTranslation();
    const locale = i18n.language;

    const facebook = useSiteMetadata().facebook;
    const twitter = useSiteMetadata().twitter;
    const instagram = useSiteMetadata().instagram;

    function submitForm(data) {
        scrollTo('.contact-container');
        setName(data.name);
        setEmail(data.email);
        setMsg(data.message);
        let subject = data.subject;

        const functionPath = `/.netlify/functions/processContactForm2`;
        const functionOptions = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({
                name,
                email,
                msg,
                locale,
                subject,
            }),
        };

        fetch(functionPath, functionOptions)
            .then(res => {
                if (!res.ok) {
                    setFormSent('error');
                } else {
                    setFormSent('sent')
                }
            })
            .catch(err => {
                console.error('There was an ERROR!!!',err)
                setFormSent('error')
            });

        windowGlobal.localStorage.setItem('firstName', name);
        setFormSent('sending');
    }

    useEffect(() => {
        // gsap.set(['.contact-container, .thanks'], { ease: 'power4.out', duration: 1.5 })
        // gsap.fromTo('.contact-container', { y: 50, autoAlpha: 0}, { y: 0, autoAlpha: 1 });
        // gsap.fromTo('.thanks', { autoAlpha: 0}, {autoAlpha: 1 });
        gsap.fromTo('#anim', { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1, ease: 'power4.out', duration: 1.5 });
    }, [])

    const nameField = register('name',{
        required: t('utils.missing_field'),
        maxLength: {
            value: 40,
            message: `${t('contact_form.max_length')} 40`
        }
    })

    const emailField = register('email',{
        required: t('utils.missing_field'),
        pattern: {
            value: /\S+@\S+\.\S+/,
            message: t('contact_form.wrong_email')
        },
    })

    const messageField = register('message',{
        required: t('utils.missing_field'),
    })

    const subjectField = register('subject');

    return (
        <Layout>
            <Seo
                title="Kontakt"
                description="Skontaktuj się ze mną. Z chęcią odpowiem na każde pytanie. Znajdź mnie także na Facebooku, Twitterze i Instagramie"
                pageUrl={url}
                pageName={location.pathname.slice(1,-1)}
            />
            <SectionContact id="anim">
                {formSent === 'unsent' &&
                    <Container fullWidth noPadding>
                        <div className="contact-container">
                            <button onClick={() => setFormSent('error')}>Error Sent</button>
                            <SectionHeader dark center>
                                <span className="underlined">
                                    {t('contact_form.contact_with_me_line_1')}
                                </span>
                                {' '}{t('contact_form.contact_with_me_line_2')}
                                <span role="img" aria-label="emoji"> 💬</span>
                            </SectionHeader>
                            <div className="contact_me">
                                <p>
                                    {t('contact_form.you_can_fill_or_write')}
                                    {' '}
                                    <a className="underline" href="mailto:kontakt@panodstrony.pl" target="_blank" rel="noreferrer">{t('contact_form.mail')}</a>,
                                    {' '}{t('contact_form.find_me_on')}{' '}
                                    <a className="underline" href={facebook} target="_blank" rel="noreferrer">{t('contact_form.facebook')}</a>,
                                    {' '}
                                    <a className="underline" href={instagram} target="_blank" rel="noreferrer">{t('contact_form.instagram')}</a>,
                                    {' '}{t('contact_form.or_on')}{' '}
                                    <a className="underline" href={twitter} target="_blank" rel="noreferrer">{t('contact_form.twitter')}</a>.
                                </p>
                            </div>
                            <p>
                            </p>
                            <ContentWrapper>
                                <StyledForm
                                    name="contact" method="post"
                                    action="/success">
                                    <UserSumbmission>
                                        <InputWrapper className="subject">
                                            <input
                                              name="subject"
                                              type="text"
                                              id="subject"
                                              onChange={(e) => {
                                                  subjectField.onChange(e)
                                              }}
                                              ref={subjectField.ref}
                                            />
                                        </InputWrapper>
                                        <NameAndMail>
                                            <InputWrapper>
                                                <input
                                                  style={{ border: errors.name && '1px solid var(--red)'}}
                                                  type="text"
                                                  placeholder=" "
                                                  id="name"
                                                  name="name"
                                                  onChange={(e) => {
                                                      nameField.onChange(e);
                                                      setName(e.target.value);
                                                  }}
                                                  ref={nameField.ref}
                                                />
                                                <ErrorMessage
                                                  errors={errors}
                                                  name="name"
                                                  render={({message}) => <small><Error/> {message}</small>}
                                                />
                                                <label htmlFor="name">{t('utils.name')}</label>
                                            </InputWrapper>
                                            <InputWrapper>
                                                <input
                                                  style={{ border: errors.email && '1px solid var(--red)'}}
                                                  type="email"
                                                  placeholder=" "
                                                  name="email"
                                                  id="email"
                                                  onChange={(e) => {
                                                      emailField.onChange(e);
                                                      setEmail(e.target.value);
                                                  }}
                                                  ref={emailField.ref}

                                                />
                                                <ErrorMessage
                                                  errors={errors}
                                                  name="email"
                                                  render={({message}) => <small><Error/> {message}</small>}
                                                />
                                                <label htmlFor="email">{t('utils.email')}</label>
                                            </InputWrapper>
                                        </NameAndMail>
                                        <Message>
                                            <InputWrapper>
                                            <textarea
                                              style={{ border: errors.message && '1px solid var(--red)'}}
                                              name="message"
                                              placeholder=" "
                                              id="message"
                                              rows="5"
                                              onChange={(e) => {
                                                  messageField.onChange(e);
                                                  setMsg(e.target.value);
                                              }}
                                              ref={messageField.ref}
                                            />
                                                <ErrorMessage
                                                  errors={errors}
                                                  name="message"
                                                  render={({message}) => <small><Error/> {message}</small>}
                                                />
                                                <label htmlFor="message">{t('utils.message')}</label>
                                            </InputWrapper>
                                        </Message>
                                    </UserSumbmission>
                                    <PureButton
                                      filled
                                      alignright
                                      marginTop
                                      onClick={handleSubmit(submitForm)}
                                    >
                                        {t('utils.send')}
                                    </PureButton>
                                </StyledForm>
                            </ContentWrapper>
                        </div>
                    </Container>
                }
                {formSent === 'sending' &&
                    <div className="thanks">
                        <Loader multiColor/>
                    </div>
                }
                {formSent === 'sent' &&
                    <div className="thanks">
                        <h1>{t('contact_form.thanks')}, {name}. {t('contact_form.msg_sent_successfully')} 🚀</h1>
                        <PureButton filled onClick={() => {
                            setFormSent('unsent');
                            setName('');
                            reset();
                        }}>
                            {t('utils.back')}
                        </PureButton>
                    </div>
                }
                {formSent === 'error' &&
                <div className="thanks">
                    <h1>{t('contact_form.error_during_sending')}</h1>
                    <PureButton filled onClick={() => {
                        setFormSent('unsent');
                        setName('');
                    }}>
                        {t('utils.back')}
                    </PureButton>
                </div>
                }
            </SectionContact>
        </Layout>
    );
}

export const query = graphql`
    query {
        image: file(name: {eq: "form_start"}) {
            childImageSharp {
                resize(width: 1200) {
                    src
                    height
                    width
                }
            }
        }
    }
`;

export default ContactPage;

