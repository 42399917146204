import styled from 'styled-components';
import { ButtonStyles } from "./ButtonStyles";

export const PureButton = styled.button`
    ${ButtonStyles};
    display: ${({ alignright}) => alignright ? 'unset' : 'grid'};
    place-items: center;
    top: 100%; 
    font-size: inherit;
    word-break: keep-all;
    transform-origin: left;

    &:focus {
        box-shadow: ${({ send }) => send ? '0 0 38.8px -5px var(--advSvg)' : 'unset' };
        //transform: ${({ send }) => send ? 'scale(1.05)' : 'unset' };
    }
`;